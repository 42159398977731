.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f5f5f5;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  
  .header1.skeleton {
    height: 60px;
  }
  
  .table-number1.skeleton,
  .table-status1.skeleton {
    width: 100px;
    height: 20px;
  }
  
  .time-occupied1.skeleton {
    width: 150px;
    height: 30px;
  }
  
  .btn-add-order1.skeleton,
  .btn-checkout1.skeleton {
    width: 120px;
    height: 40px;
  }
  
  .card-header2.skeleton {
    height: 40px;
  }
  
  .order-item2 .item-info2.skeleton,
  .order-item2 .item-pricing2.skeleton {
    width: 100%;
    height: 20px;
    margin-bottom: 10px;
  }
  
  .total-amount.skeleton {
    width: 100%;
    height: 30px;
  }