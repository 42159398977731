@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.menu-item-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.menu-item-card {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 15px;
  flex-basis: calc(50% - 20px);
  max-width: calc(50% - 20px);
  width: 100%;
  display: flex;
  gap: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.item-code-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #6c5ce7;
  color: white;
  padding: 4px 8px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 500;
}

.menu-item-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.item-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.item-image:hover {
  transform: scale(1.1);
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item-name {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.item-description {
  font-size: 13px;
  color: #888;
  margin: 0;
}

.item-info {
  display: flex;
  gap: 16px;
}

.icon-info {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: #666;
}

.icon-info .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.icon-info .dot.veg {
  background-color: #4caf50;
}

.icon-info .dot.non-veg {
  background-color: #f44336;
}

.item-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item-price {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.quantity-picker {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 4px;
  transition: background-color 0.3s ease;
}

.quantity-picker:hover {
  background-color: #e5e5e5;
}

.quantity-controls {
  display: flex;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
}

.quantity-controls button {
  background: linear-gradient(45deg, #6c5ce7, #00cec9);
  color: #fff;
  border: none;
  padding: 4px 8px;
  font-size: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
}

.quantity-controls button:hover {
  transform: translateX(-3px);
  background: linear-gradient(45deg, #00cec9, #6c5ce7);
}

.quantity-controls button:active {
  transform: translateX(3px);
}

.quantity-controls .quantity {
  background-color: #fff;
  color: #333;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 500;
  transition: transform 0.3s ease;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.quantity.animate {
  animation: bounce 0.3s ease;
}

@media (max-width: 768px) {
  .menu-item-card {
    flex-direction: column;
    max-width: 100%;
  }

  .item-image {
    width: 100%;
    height: auto;
    max-height: 200px;
  }

  .item-details {
    gap: 10px;
  }

  .item-info {
    flex-wrap: wrap;
  }

  .menu-item-row {
    flex-direction: column;
    align-items: center;
  }
}
.item-image {
  display: none; /* Hide the item image on mobile view */
}
@media (max-width: 480px) {
  .menu-item-card {
    padding: 10px;
    width: 68%;
    
  }

  .item-image {
    display: none; /* Hide the item image on mobile view */
  }

  .item-details {
    gap: 5px;
  }

  .item-info {
    gap: 8px;
  }

  .item-name {
    font-size: 14px;
  }

  .item-description {
    font-size: 12px;
  }

  .icon-info {
    font-size: 12px;
  }

  .item-price {
    font-size: 13px;
  }

  .quantity-controls button {
    font-size: 8px;
    padding: 3px 6px;
  }

  .quantity-controls .quantity {
    font-size: 9px;
    padding: 3px 6px;
  }
}
