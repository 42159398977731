:root {
  --bg-color-light: #f8f9fa;
  --bg-color-dark: #2d3748;
  --text-color-light: #1a202c;
  --text-color-dark: #e2e8f0;
  --input-bg-light: #ffffff;
  --input-bg-dark: #4a5568;
  --card-bg-light: #ffffff;
  --card-bg-dark: #4a5568;
  --card-text-color-light: #1a202c;
  --card-text-color-dark: #e2e8f0;
}

[data-theme="light"] {
  --bg-color: var(--bg-color-light);
  --text-color: var(--text-color-light);
  --input-bg: var(--input-bg-light);
  --card-bg: var(--card-bg-light);
  --card-text-color: var(--card-text-color-light);
}

[data-theme="dark"] {
  --bg-color: var(--bg-color-dark);
  --text-color: var(--text-color-dark);
  --input-bg: var(--input-bg-dark);
  --card-bg: var(--card-bg-dark);
  --card-text-color: var(--card-text-color-dark);
}

/* General styles */
.menu-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.menu-container {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 100px;
  width: 80%;
}

/* Sticky top bar */
.sticky-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #6c5ce7, #00cec9);
  color: #fff;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  border-radius: 15px;
  width: 80%;
}

.left-section, .right-section {
  display: flex;
  align-items: center;
}

/* Table status */
.table-status {
  display: flex;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #00cc66;
  border-radius: 50%;
  margin-right: 6px;
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.status-text {
  font-size: 16px;
}

/* Order details */
.order-details {
  display: flex;
  align-items: center;
}

/* Sticky bottom bar */
.sticky-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  z-index: 100;
  width: 80%;
  left: 19%;
}

.sticky-bottom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-count {
  display: flex;
  align-items: center;
}

.item-count .label {
  font-size: 12px;
  font-weight: 500;
  color: #6c757d;
  margin-right: 8px;
}

.item-count .value {
  font-size: 14px;
  font-weight: 600;
  color: #343a40;
}

.confirm-order-btn {
  background: linear-gradient(135deg, #6c5ce7, #00cec9);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-order-btn:hover {
  background-color: #6c5ce7;
}

.confirm-order-btn.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-right: 16px;
  font-size: 12px;
}

/* Search and filter container */
.filter-search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--bg-color);
  position: sticky;
  top: 80px;
  z-index: 99;
  width: 100%;
}

.filter-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.search-container {
  flex: 1;
  margin-left: 16px;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--input-bg);
}

/* MenuItemCard styles */
.menu-item-card {
  background-color: var(--card-bg);
  color: var(--card-text-color);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-item-card .item-name {
  font-size: 16px;
  font-weight: 600;
  color: var(--card-text-color);
}

.menu-item-card .item-description,
.menu-item-card .item-info {
  font-size: 14px;
  font-weight: 500;
  color: var(--card-text-color);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .sticky-top-bar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .left-section, .right-section {
    width: 100%;
    justify-content: space-between;
  }

  .left-section {
    margin-bottom: 10px;
  }

  .filter-search-container {
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
  }

  .filter-container {
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .search-container {
    width: 100%;
    margin-left: 0;
  }

  .menu-item-row {
    align-items: flex-start;
    padding: 10px;
  }

  .menu-item-row img {
    width: 100%;
    height: auto;
  }

  .menu-item-row .item-info {
    width: 100%;
  }

  .menu-item-row .item-name, .menu-item-row .item-price {
    font-size: 14px;
  }
  .sticky-bottom {
    position: fixed;
    top:86%
  }
  
}

@media (max-width: 480px) {
  .sticky-top-bar {
    padding: 8px;
    font-size: 14px;
    width: 47%;
  }

  .sticky-top-bar .left-section, .sticky-top-bar .right-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .sticky-top-bar .back-button {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .sticky-top-bar .table-number {
    font-size: 14px;
  }

  .filter-search-container {
    padding: 8px;
    flex-direction: column;
    align-items: stretch;
    width: 50%;
  }

  .filter-container {
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }

  .search-container {
    width: 100%;
    margin-left: 0;
  }

  .menu-item-row {
    padding: 8px;
    font-size: 12px;
  }

  .menu-item-row .item-name, .menu-item-row .item-price {
    font-size: 12px;
  }
}

/* Adjusting the layout and overflow properties */
.menu-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.menu-container {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 100px;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width */
}

.sticky-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  z-index: 100;
  width: calc(100% - 16px); /* Adjust to prevent overlap */
  left: 8px; /* Center the bottom bar */
}

/* Ensure the parent container is scrollable */
.menu-container {
  height: calc(100vh - 100px); /* Adjust based on your layout */
  overflow-y: auto;
}

/* Styles for the menu item rows */
.menu-item-row {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  justify-content: center;
  width: 70%; /* Ensure it uses full width */
  box-sizing: border-box; /* Include padding in the width */
}

/* General styles */
.menu-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.menu-container {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 100px;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width */
}

/* Sticky top bar */
.sticky-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #6c5ce7, #00cec9);
  color: #fff;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  border-radius: 15px;
  width: 100%;
}

.left-section, .right-section {
  display: flex;
  align-items: center;
}

/* Table status */
.table-status {
  display: flex;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #00cc66;
  border-radius: 50%;
  margin-right: 6px;
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.status-text {
  font-size: 16px;
}

/* Order details */
.order-details {
  display: flex;
  align-items: center;
}

/* Sticky bottom bar */
.sticky-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  z-index: 100;
  width: calc(100% - 16px); /* Adjust to prevent overlap */
  left: 8px; /* Center the bottom bar */
}

.sticky-bottom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-count {
  display: flex;
  align-items: center;
}

.item-count .label {
  font-size: 12px;
  font-weight: 500;
  color: #6c757d;
  margin-right: 8px;
}

.item-count .value {
  font-size: 14px;
  font-weight: 600;
  color: #343a40;
}

.confirm-order-btn {
  background: linear-gradient(135deg, #6c5ce7, #00cec9);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-order-btn:hover {
  background-color: #6c5ce7;
}

.confirm-order-btn.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-right: 16px;
  font-size: 12px;
}

.filter-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.search-container {
  flex: 1;
  margin-left: 16px;
  position: relative;
  right: 58px;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--input-bg);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .sticky-top-bar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .left-section, .right-section {
    width: 100%;
    justify-content: space-between;
  }

  .left-section {
    margin-bottom: 10px;
  }

  .filter-search-container {
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
  }

  .filter-container {
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .search-container {
    width: 100%;
    margin-left: 0;
  }

  .menu-item-row {
    align-items: flex-start;
    padding: 10px;
  }

  .menu-item-row img {
    width: 100%;
    height: auto;
  }

  .menu-item-row .item-info {
    width: 100%;
  }

  .menu-item-row .item-name, .menu-item-row .item-price {
    font-size: 14px;
  }
  
  .sticky-bottom {
    position: fixed;
    top:86%;
  }
}

@media (max-width: 480px) {
  .sticky-top-bar {
    padding: 8px;
    font-size: 14px;
    width: 47%;
  }

  .search-container{
    position: relative;
    right: 0px;
  }

  .sticky-top-bar .left-section, .sticky-top-bar .right-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .sticky-top-bar .back-button {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .sticky-top-bar .table-number {
    font-size: 14px;
  }

  .filter-search-container {
    padding: 8px;
    flex-direction: column;
    align-items: stretch;
    width: 50%;
  }

  .filter-container {
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }

  .search-container {
    width: 100%;
    margin-left: 0;
  }

  .menu-item-row {
    padding: 8px;
    font-size: 12px;
    margin: 0px;
    width: 100%;
  }

  .menu-item-card{
    width: 100%;
  }
  .menu-item-row .item-name, .menu-item-row .item-price {
    font-size: 12px;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .sticky-top-bar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    font-size: 14px;
  }

  .left-section, .right-section {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .back-button {
    font-size: 18px;
    cursor: pointer;
    margin-right: 10px;
  }

  .table-number {
    font-size: 16px;
  }

  .dot {
    margin-right: 6px;
  }

  .status-text {
    font-size: 14px;
  }

  .order-details {
    display: flex;
    gap: 10px;
  }

  .duration, .amount {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .amount {
    font-size: 16px;
  }

  .hide-mobile {
    display: none;
  }
}

@media (max-width: 480px) {
  .sticky-top-bar {
    padding: 8px;
    width: 100%;
  }

  .left-section, .right-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .back-button {
    margin-bottom: 8px;
  }

  .table-number {
    font-size: 14px;
  }

  .table-number, .table-status, .order-details {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .filter-search-container {
    flex-direction: column;
    align-items: stretch;
    padding: 8px;
    width: 100%;
  }

  .filter-container {
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }

  /* MenuPage.css */

/* Ensuring the filter container allows horizontal scrolling */
.filter-container {
  overflow-x: auto !important;
  white-space: nowrap !important;
  display: flex !important;
  align-items: center !important;
}

/* Hiding the scrollbar on mobile devices */
.filter-container::-webkit-scrollbar {
  display: none !important;
}

.filter-container {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

/* Make sure filter categories are displayed inline */
.filter-container .filter-category {
  display: inline-block !important;
  margin-right: 10px !important; /* Adjust the spacing as needed */
}

/* Add styles for filter categories */
.filter-categories {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.filter-category {
  padding: 10px 15px !important;
  cursor: pointer !important;
  white-space: nowrap !important;
}

.filter-category.active {
  background-color: #ececec !important; /* Adjust the active state styling as needed */
  border-radius: 5px !important;
}

/* Add mobile view adjustments */
@media (max-width: 768px) {
  .filter-search-container {
    display: flex !important;
    flex-direction: column !important;
  }

  .filter-container {
    flex-shrink: 0 !important;
  }

  .search-container {
    margin-top: 10px !important;
  }
}


  .search-container {
    width: 100%;
    margin-left: 0;
  }

  .menu-item-row {
    padding: 8px;
    font-size: 12px;
  }

  .menu-item-row .item-name, .menu-item-row .item-price {
    font-size: 12px;
  }

  .sticky-bottom {
    position: fixed;
    top: 86%;
  }

  .hide-mobile {
    display: none;
  }
}

.filter-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* for smooth scrolling on iOS */
}

.filter-container .category-item {
  flex: 0 0 auto; /* Ensures each item stays inline and doesn't wrap */
  margin-right: 10px; /* Adjust as needed for spacing between items */
}