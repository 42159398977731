.table-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
  
  .table-details-card {
    width: 100%;
    max-width: 800px;
    background-color: var(--color-card);
    border-radius: 10px;
    box-shadow: var(--shadow);
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .header .back-btn {
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .header .order-details {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .table-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .table-info .table-number {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .table-info .customer-name {
    font-size: 1.25rem;
  }
  
  .table-status {
    display: flex;
    align-items: center;
  }
  
  .table-status .status-icon {
    margin-right: 0.5rem;
  }
  
  .table-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  
  .order-details-card {
    width: 100%;
    background: linear-gradient(135deg, #a6c0fe, #f68084);
    border-radius: 10px;
    padding: 1rem;
    box-shadow: var(--shadow);
  }
  
  .order-card {
    margin-bottom: 1rem;
  }
  
  .card-header {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .order-items {
    margin-bottom: 1rem;
  }
  
  .order-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .item-info {
    display: flex;
    align-items: center;
  }
  
  .item-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .veg {
    color: green;
  }
  
  .non-veg {
    color: red;
  }
  
  .item-details {
    display: flex;
    flex-direction: column;
  }
  
  .item-pricing {
    display: flex;
    align-items: center;
  }
  
  .item-price,
  .item-quantity {
    margin-right: 0.5rem;
  }
  
  .total-amount {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
  
  .animation-pulse {
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  