@media print {
    body * {
      visibility: hidden;
    }
    .print-bill, .print-bill * {
      visibility: visible;
    }
    .print-bill {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  
  .print-bill {
    font-family: monospace;
    width: 300px;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #000;
  }
  
  .bill-header {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .bill-header h1 {
    font-size: 18px;
    margin: 0;
  }
  
  .bill-header p {
    font-size: 12px;
    margin: 2px 0;
  }
  
  .bill-info {
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  .bill-items {
    width: 100%;
    font-size: 12px;
    border-collapse: collapse;
  }
  
  .bill-items th, .bill-items td {
    border-top: 1px dotted #000;
    border-bottom: 1px dotted #000;
    padding: 2px;
    text-align: left;
  }
  
  .bill-items th:last-child, .bill-items td:last-child {
    text-align: right;
  }
  
  .bill-summary {
    font-size: 12px;
    margin-top: 10px;
  }
  
  .bill-summary .total {
    font-weight: bold;
  }
  
  .bill-footer {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }