@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.filter-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  padding: 10px;
  width: 80%;
}

.filter-categories {
  display: inline-flex;
  gap: 10px;
}

.category {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  user-select: none;
}

.category i {
  font-size: 18px;
  color: #ff8a65;
}

.category span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.category.active {
  background: linear-gradient(45deg, #6c5ce7, #00cec9);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.category.active i {
  color: #fff;
  animation: pulse 1s infinite;
}

.category.active span {
  color: #fff;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .filter-container {
    padding: 5px;
  }

  .category {
    padding: 8px 10px;
  }

  .category span {
    font-size: 12px;
  }

  .category i {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .category {
    padding: 6px 8px;
    flex-direction: column;
    align-items: center;
  }

  .filter-container {
    width: 100%;
  }
  .category span {
    font-size: 11px;
  }

  .category i {
    font-size: 14px;
  }
}
